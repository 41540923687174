import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../layout/layout';
import SEO from '../meta/seo';

const AppPrivacy = ({ data }) => (
  <Layout>
    <SEO title={`${data.ticketCounterPrivacy.app.name} Privacy Policy`} />

    <div className="container space-around">
      <div
        className="text-prose M16 md:M18"
        dangerouslySetInnerHTML={{ __html: data.ticketCounterPrivacy.body }}
      />
    </div>
  </Layout>
);

AppPrivacy.propTypes = {
  data: PropTypes.shape({
    ticketCounterPrivacy: PropTypes.shape({
      body: PropTypes.string,
      app: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }),
};

export default AppPrivacy;

export const query = graphql`
  query AppPrivacy($app: String!) {
    ticketCounterPrivacy(app: { id: { eq: $app } }) {
      app {
        name
      }
      body
    }
  }
`;
